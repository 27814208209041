<template>
  <div class="container">
    <div class="logo"><img src="../assets/images/logo.png" /></div>
    <div class="input-item">
      <input v-model="email" type="text" placeholder="请输入手机号" autocomplete="off" />
    </div>
    <div class="input-item">
      <input v-model="vcode" type="text" placeholder="请输入验证码" autocomplete="off" />
      <div class="btn" :class="time > 0 ? 'not' : ''" @click="handleGetVCode">{{ time > 0 ? time + 's' : '获取验证码' }}</div>
    </div>
    <div class="input-item">
      <input v-model="pwd" type="password" placeholder="请输入密码" autocomplete="off" />
    </div>
    <div class="input-item">
      <input v-model="rePwd" type="password" placeholder="请输入确认密码" autocomplete="off" />
    </div>
    <div class="input-item">
      <input v-model="inviteCode" type="text" placeholder="请输入邀请码" autocomplete="off" />
    </div>
    <div class="agreement"><div class="dot" :class="isAgreement ? 'active' : ''" @click="handleChangeAgreement"><div v-if="isAgreement" class="foc"></div></div>已阅读并同意 <router-link to="/agreement?id=2">《用户协议》</router-link> 及 <router-link to="/agreement?id=3">《隐私协议》</router-link></div>
    <div class="submit-btn" @click="handleRegister">立即注册</div>
  </div>
</template>
<script>
import request from '@/utils/request'

export default {
  name: 'Home',
  data() {
    return {
      system: '',
      settingData: {},
      email: '',
      vcode: '',
      pwd: '',
      rePwd: '',
      inviteCode: '',
      isAgreement: false,
      time: 0,
      timer: null,
      loading: false
    }
  },
  mounted() {
    if (this.$route.query.invite_code) {
      this.inviteCode = this.$route.query.invite_code
    }
    this.checkSystem()
    this.handleGetSetting()
  },
  methods: {
    checkSystem () {
      const ua = navigator.userAgent
      const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
      if (isiOS) {
        this.system = 'ios'
      } else if (isAndroid) {
        this.system = 'android'
      } else {
        this.system = 'pc'
      }
    },
    handleGetSetting() {
      const api = 'api/getSetting'
      const data = {
        id: 1
      }
      request({
        url: api,
        method: 'get',
        params: data
      }).then(res => {
        this.settingData = res || {}
      })
    },
    handleDownloadApp() {
      const { system, settingData } = this
      if (system !== 'pc') {
        this.$dialog.alert({
          message: '注册成功, 即将开始下载应用程序'
        }).then(() => {
          if (system === 'ios') {
            window.location.href = settingData.ios_down_url
          } else if (system === 'android') {
            window.location.href = settingData.android_down_url
          }
        })
      }
    },
    handleChangeAgreement() {
      this.isAgreement = !this.isAgreement
    },
    handleGetVCode() {
      const  { email, time, loading } = this
      if (time > 0 || loading) {
        return
      }
      this.loading = true
      // const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      // const regEmail = /^1\d{10}$/
      // if (!regEmail.test(email)) {
      //   this.$toast('请输入正确的手机号')
      //   return
      // }
      if (!email) {
        this.$toast('请输入正确的手机号')
        return
      }
      const api = 'api/sendsms'
      const data = {
        email
      }
      request({
        url: api,
        method: 'post',
        data
      }).then(res => {
        this.loading = false
        if (res.error == 0) {
          this.handleSetTimer()
        }
        this.$toast(res.info)
      })
    },
    handleSetTimer() {
      let time = 60
      this.time = time
      this.timer = setInterval(() => {
        time--
        if (time <= 0) {
          time = 0
          clearInterval(this.timer)
        }
        this.time = time
      }, 1000)
    },
    handleRegister() {
      const { email, vcode, pwd, rePwd, inviteCode, isAgreement, loading } = this
      if (loading) {
        return
      }
      // const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      // const regEmail = /^1\d{10}$/
      // if (!regEmail.test(email)) {
      //   this.$toast('请输入正确的手机号')
      //   return
      // }
      if (!email) {
        this.$toast('请输入正确的手机号')
        return
      }
      if (!vcode) {
        this.$toast('请输入验证码')
        return
      }
      if (!pwd) {
        this.$toast('请输入密码')
        return
      }
      if (!rePwd) {
        this.$toast('请确认密码')
        return
      }
      if (pwd != rePwd) {
        this.$toast('两次输入密码不一致')
        return
      }
      if (!isAgreement) {
        this.$toast('请同意用户协议')
        return
      }
      this.loading = true
      const api = 'api/register'
      const data = {
        email,
        pwd,
        v_code: vcode,
        invite_code: inviteCode,
      }
      request({
        url: api,
        method: 'post',
        data
      }).then(res => {
        this.loading = false
        if (res.error == 0) {
          this.handleDownloadApp()
        } else {
          this.$toast(res.info)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #121325;
  .logo {
    width: 1.72rem;
    height: 1.72rem;
    margin-bottom: .58rem;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .input-item {
    width: 5.79rem;
    height: .96rem;
    background-color: rgba(246, 248, 250, .12);
    border-radius: .2rem;
    margin-bottom: .44rem;
    padding: 0 .38rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      flex: 1;
      font-size: .28rem;
      color: #fff;
    }
    .btn {
      white-space: nowrap;
      font-size: .26rem;
      color: #0066e1;
      &.not {
        color: #999;
      }
    }
  }
  .agreement {
    width: 5.6rem;
    font-size: .24rem;
    display: flex;
    align-content: center;
    color: #666;
    .dot {
      width: .18rem;
      height: .18rem;
      padding: .04rem;
      border: 1px solid #333;
      margin-right: .2rem;
      border-radius: .04rem;
      &.active {
        border-color: #26C4FE;
      }
      .foc {
        width: 100%;
        height: 100%;
        background-color: #26C4FE;
        border-radius: .04rem;
      }
    }
    a {
      color: #26C4FE;
    }
  }
  .submit-btn {
    width: 6.54rem;
    height: .96rem;
    border-radius: .2rem;
    text-align: center;
    line-height: .96rem;
    background-color: #2C9CFF;
    color: #fff;
    font-size: .3rem;
    margin-top: .8rem;
  }
}

</style>
